import React from 'react'
import { graphql } from 'gatsby'

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/style.scss"
import "../styles/main.scss"

import BlockList from '../components/block-list/block-list';

class RetailStoresPage extends React.Component {
  render() {
    let slotsList = this.props.data.allContentfulPage.nodes[0].slots;
    let page = this.props.data.allContentfulPage.edges[0].node;
    return (
      <Layout bannerImage={page.headerImage} pageTitle={page.headerTitle}>
         <SEO title={page.title} description={page.description.description} pathname={page.slug} keywords={page.keywords} image={page.headerImage.file.url}/>
        {slotsList.map((slot, index) => {
          return <section id={slot.contents[0].sectionId} className={slot.backgroundClass} key={index}>
            <BlockList data={slot.contents[0]}></BlockList>
            </section>
        })}
      </Layout >
    )
  }
}

export default RetailStoresPage

export const pageQuery = graphql`
  query RetailStoresTemplateQuery($locale: String!) {
    allContentfulPage(filter: {slug: {eq: "retail-stores"}, node_locale: {eq: $locale}}) {
      totalCount
      edges {
        node {
          id
          node_locale
          title
          keywords
          headerTitle
          description {
            description
          }
          slug
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid {
              base64
              ...GatsbyContentfulFluid_withWebp
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      nodes {
        slots {
          backgroundClass
          name
          contents {
            __typename
            ...on ContentfulSlotBlocksList{
              title
              sectionId
              showLetterSorting 
              description {
                internal {
                  content
                  description
                  ignoreType
                  mediaType
                }
              }
              topics {
                name
                
              }
              blocks {
                name
                sortingLetter
                order
                backgroundClass
                body {
                  json
                }
              }
            }
            ... on ContentfulSlotCarousel {
              images {
                title
                colorInverted
                alignment
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
            ... on ContentfulSlotVideo {
              title
              videoFile {
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
            ... on ContentfulSlotSection {
             __typename
             sectionHeading
            title
            sectionId
            subTitle
            imagePosition
            isPrimary
            underlineHeader
            body {
              json
            }
            image {
              title
              file {
                url
                fileName
                contentType
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            backgroundClass
            imageWidth
            bodyWidth
            linkUrl
          
            }
            ... on ContentfulSlotCallToAction {
              title
              subTitle
              content {
                name
                listItems {
                  linkDescription
                  linkUrl
                  image {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
